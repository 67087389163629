// we will start by importing react dom

import ReactDom from 'react-dom'
// will import App component from App.jsx
import App from './App'
// we will also import our css component
import './index.css'

// 1A we now we need to render something into the index.html root div
// we will render our app.jsx component
ReactDom.render(<App/>, document.querySelector("#root"));