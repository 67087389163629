
import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpeg'
import {FaAward} from 'react-icons/fa'
import {TbFileCertificate} from 'react-icons/tb'
import{VscFolderLibrary} from 'react-icons/vsc'



const about = () => {
  return (
    <section id='about'>
      <h5>Get to know me </h5>
      <h2>About Me</h2>
      <div className="container about_container">
        <div className="about_me">
           <img src={ME} alt="About Image" className='about_me-image'/>
        </div>

        <div className="about_content">
             <div className="about_cards">
              <article className='about_card'>
                <FaAward  className='about_icon'/>
                <h5>Experience</h5>
                <small>3+ years working</small>
              </article>
              <article className='about_card'>
                <TbFileCertificate  className='about_icon'/>
                <h5>Qualification</h5>
                <small>National Diploma In Engineering Computer Systems </small>
              </article>
              <article className='about_card'>
                <VscFolderLibrary  className='about_icon'/>
                <h5>Project</h5>
                <small>10+ completed</small>
              </article>
             </div>
             
             <p>
             As a Software Developer, I actively seek opportunities to expand my skills and knowledge. With a strong foundation in computer science principles and expertise in JavaScript, PHP, Java, and Python, I am well-prepared to tackle complex challenges. My collaborative experience in team projects has honed my effectiveness, and I am deeply motivated to continually grow and excel in my role. Committed to staying current with the latest trends, I strive for ongoing development in the ever-evolving field of technology.
             </p>
             <a href="#contact" className='btn btn-primary'>Let's Talk</a>

        </div>
      </div>
    </section>
  )
}

export default about
