import React from 'react'
import "./header.css"
import CTA from './CTA'
import ME from '../../assets/me.png'
import Socials from './Socials'


const Header = () => {
  return (
    
    <header>
     <div className="container header_container">
      <h5>Hello I'm</h5>
      <h1>Judel Mukadi</h1>
      <h5 className='text-light'>Software Developer</h5>
      <CTA />
      <Socials />
      <div className="main_image">
        <img src={ME} alt="a picture of me" />
      </div>

      <a href="#contact" className='scroll_down'>Scroll Down</a>
     </div>
    </header>
  )
}

export default Header