import React from 'react'
import "./services.css"
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I offer </h5>
      <h2>My Skills</h2>

      <div className="container services_container">
        {/* End of UI/UX */}
        <article className="service">
          <div className="service_head">
            <h3>Software Development</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Search Engine Optimization(SEO) Skills.</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Android Studio.</p>
            </li>  
               <li>
              <BiCheck className='service_list-icon'/>
              <p>Responsive Web Designing Skills.</p>
            </li>
             <li>
              <BiCheck className='service_list-icon'/>
              <p>Hosting.</p>
            </li>
             <li>
              <BiCheck className='service_list-icon'/>
              <p>Testing and Debugging.</p>
            </li>
             <li>
              <BiCheck className='service_list-icon'/>
              <p>JS Libraries and Frameworks.</p>
            </li>
             <li>
              <BiCheck className='service_list-icon'/>
              <p>Backend Tech.</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>version control.</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>User Experience (UX)/ User Interface (UI).</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Web Security.</p>
            </li>
           
          </ul>
        </article>
        {/* End of software development*/}
        <article className="service">
          <div className="service_head">
            <h3>Cloud Computing </h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Linux.</p>
            </li>  
             <li>
              <BiCheck className='service_list-icon'/>
              <p>DNS management</p>
            </li>
             <li>
              <BiCheck className='service_list-icon'/>
              <p> Network management.</p>
            </li>
             <li>
              <BiCheck className='service_list-icon'/>
              <p>Cloud security.</p>
            </li>
             <li>
              <BiCheck className='service_list-icon'/>
              <p>Containers.</p>
            </li>
             <li>
              <BiCheck className='service_list-icon'/>
              <p>Data and application migration and milti-cloud deployment.</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Performance testing, metrics and analytics.</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Cloud orchestration.</p>
            </li>
            <li>
              <BiCheck className='service_list-icon'/>
              <p>Automation.</p>
            </li>
          </ul>
        </article>
        {/* End of Cloud computing  */}
      </div>
    </section>
  )
}

export default Services