import React from 'react'
import "./experience.css"
import{BsPatchCheckFill} from 'react-icons/bs'

const experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience_container">
         <div className="experience_fronted">
          <h3>Frontend Development</h3>
          <div className="experience_content">
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                 <h5>HTML</h5>
                 <small className='text-light'>Experienced</small>
              </div>
            </article>
             <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
               <div>
                 <h4>CSS / BootStrap</h4>
                 <small className='text-light'>Intermediate</small>
              </div> 
            </article>
             <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                 <h4>JavaScript</h4>
                 <small className='text-light'>Experienced</small>
              </div>  
            </article>
             <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                 <h4>React JS</h4>
                 <small className='text-light'>Experienced</small>
              </div>
            </article>
             <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                 <h4>Vue JS</h4>
                 <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                 <h4>WordPress</h4>
                 <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                 <h4>AngularJS</h4>
                 <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                 <h4>Bootstrap</h4>
                 <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                 <h4>Tailwind</h4>
                 <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                 <h4>jQuery</h4>
                 <small className='text-light'>Experienced</small>
              </div>
            </article>
          </div>
         </div>

         {/* End Of Fronted */}
         
         <div className="experience_backend">
          <h3>Backend Development</h3>
          <div className="experience_content">
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                 <h4>PHP</h4>
                 <small className='text-light'>Intermediate</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                 <h4>Node JS</h4>
                 <small className='text-light'>Intermediate</small>
              </div>
            </article>
             <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
               <div>
                  <h4>MongoDB</h4>
                  <small className='text-light'>Intermediate</small>
              </div>
            </article>
             <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                  <h5>Laravel</h5>
                  <small className='text-light'>Intermediate</small>
              </div>
            </article>
             <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                  <h5>Golang</h5>
                  <small className='text-light'>Basic</small>
              </div>
            </article>
             <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                  <h5>MySQL</h5>
                  <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                  <h5>Linux</h5>
                  <small className='text-light'>Experienced</small>
              </div>
            </article>
              <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                  <h5>Apache/Nginx</h5>
                  <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                  <h5>GitLab/GitHub</h5>
                  <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience_details'>
              <BsPatchCheckFill className='experience_details-icon' />
              <div>
                  <h5>AWS/Azure/Oracle Cloud</h5>
                  <small className='text-light'>Intermediate</small>
              </div>
            </article>
          </div>
         </div>
      </div>
    </section>
  )
}

export default experience

