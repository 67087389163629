

import React from 'react'
import "./contact.css"
import {MdOutlineEmail} from 'react-icons/md'
import {BsWhatsapp} from 'react-icons/bs'
import {ImLocation2} from 'react-icons/im'
import  {useRef as UseRef } from 'react'
import emailjs from 'emailjs-com'

const contact = () => {
  const form = UseRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_cm96np2', 'template_cy50mbu', form.current, 'Na2nf9Gt2lWmWdjMQ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact_container">
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Full name' required />
          <input type="tel" name='phone' placeholder='Phone' required />
          <input type="Email" name='email' placeholder='Email' required />
          <textarea name="message"  rows="7" placeholder='Message'></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
        <div className="contact_options">
          <article className='contact_option'>
            <MdOutlineEmail className='contact_option-icon'/>
            <h4>Email</h4>
            <h5>judel.mukadi24@gmail.com</h5>
            <a href="mailto:judel.mukadi24@gmail.com" target="_blank">Send a message</a>
          </article>
          <article className='contact_option'>
            <BsWhatsapp className='contact_option-icon'/>
            <h4>WhatsApp</h4>
            <h5>+27634242983</h5>
            <a href="https://wa.me/+27634242983" target="_blank">Contact me by whatsapp</a>
          </article>
          <article className='contact_option'>
            <ImLocation2  className='contact_option-icon'/>
            <h4>Location</h4>
            <h5>Johannesburg</h5>
            <h6>South Africa</h6>
          </article>

        </div>
      </div>
    </section>
  )
}

export default contact