import React from 'react'
import "./portfolio.css"
import image1 from '../../assets/portfolio1.png'
import image2 from '../../assets/portfolio2.png'
import image3 from '../../assets/portfolio3.png'
import image4 from '../../assets/portfolio4.png'
import image5 from '../../assets/portfolio5.png'
import image6 from '../../assets/portfolio6.png'


// project array

const data = [
  {
    id: 1,
    image: image1,
    title: 'Task Tracker',
    description: 'A task tracker web application that utilizes CRUD functionality to allow users to create and manage lists of tasks or to-do items. The application includes various fields for task information, such as the task name, due date, priority level, and notes or comments. In addition, the application features auto due date completion and real-time updates to assist users in staying organized and on track.',   
    tags: 'Vuejs, Bootstrap, Cloud Firestore ',
    github: 'https://github.com/judel-code',
    demo: 'https://task-tracker.judelmukadi.co.za/'
  },
  {

    id: 2,
    image: image2,
    title: 'Project Portfolio',
    description: 'A web application designed for showcasing portfolio projects, built using React.js and stored on a secured Virtual Private Server (VPS) that is configured to host and run multiple applications on an Nginx web server. This application allows users to display their skills and projects to potential employers or clients, and the VPS hosting the application is secured to ensure the protection and accessibility of the portfolio at all times.',
    tags: 'React, CSS, EmailJS, Nginx',
    github: 'https://github.com/judel-code',
    demo: 'https://www.judelmukadi.co.za/'
  },
  {
    id: 3,
    image: image3,
    title: 'Fitness clubX',
    description: 'A web application designed for fitness enthusiasts, built with React.js and Material-UI and integrating multiple Rapid APIs, which provides a range of features to assist users in reaching their fitness goals. These features include a library of instructional videos and articles that teach users new exercises and techniques.',
    tags: 'React, Material-UI, Rapid API',
    github: 'https://github.com/judel-code/fitness-clubX',
    demo: 'https://clubx.judelmukadi.co.za/'
  },
  {
    id: 4,
    image: image4,
    title: 'Smart Budget App',
    description: 'JavaScript Smart Budget App, in which users can enter their spending and incomes, and entries are automatically saved. Users can remove or update entries, and the app will display the total income and expense, as well as a basic pie chart and the balance.',
    tags: 'JavaScript, HTML, CSS',
    github: 'https://github.com/judel-code/Smart-budget-App/tree/master',
    demo: 'https://smart-budget.judelmukadi.co.za/'
  },
  {
    id: 5,
    image: image5,
    title: 'Mobile Friendly Weather App',
    description: "The OpenWeather API is used to create a mobile-friendly weather app. The application retrieves the user's location from the user's browser and then uses the OpenWeather Api to retrieve the location's weather data for the next seven days. " ,
    tags: 'JavaScript, HTML, CSS, OpenWeather API ',
    github: 'https://github.com/judel-code/Mobile-friendly-weather-app',
    demo: 'https://weather.judelmukadi.co.za/'
  },
  {
    id: 6,
    image: image6,
    title: 'Retro Javascript Games',
    description: 'A collection of retro video games created using various Javascript methods as a means of learning and understanding how different Javascript methods can be utilized and implemented within different projects',
    tags: 'JavaScript, HTML, CSS',
    github: 'https://github.com/judel-code/retro-javascript-games',
    demo: 'https://games.judelmukadi.co.za/'
  }
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Projects</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio_container">
        {
          data.map(({id, image, title, github, demo,tags,description}) =>
          {
            return (
              <article key={id} className="portfolio_item">
                <div className="portfolio_item-image">
                  <img className="images" src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <h5>{description}</h5>
                <h4>{tags}</h4>
                <div className="portfolio_item-cta">
                  <a href={github} className="btn" target='_blank' rel="noreferrer">Github</a>
                  <a href={demo} className="btn btn-primary" target='_blank' rel="noreferrer">Live Demo</a>
                </div>
              </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio