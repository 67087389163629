import React from 'react'
import "./footer.css"
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaCodepen} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer_slogan'>JUDEL</a>
      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Skills</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>  
      </ul>

      <div className="footer_socials">
        <a href='https://www.linkedin.com/in/judel-mukadi-9224281ab/' target="-blank"><BsLinkedin /></a>
      <a href='https://github.com/judel-code' target="-blank"><FaGithub /></a>
      <a href='https://codepen.io/judel' target="-blank"><FaCodepen /></a>
      </div>

      <div className="footer_copyright">
        <small>&copy; Judel Mukadi. All rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer