import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaCodepen} from 'react-icons/fa'

const socials = () => {
  return (
    <div className='header_socials'>
     {/* we set the target to blank, the links open in another tab */}
      <a href='https://www.linkedin.com/in/judel-mukadi-9224281ab/' target="-blank"><BsLinkedin /></a>
      <a href='https://github.com/judel-code' target="-blank"><FaGithub /></a>
      <a href='https://codepen.io/judel' target="-blank"><FaCodepen /></a>

    </div>
  )
}

export default socials
